import React, { FC } from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

import {
  Button

} from '@bscs-dev-team/bscs-design-system-core'

import {
  GatsbyFluidImage
} from '../templates/template-types'

import Layout from '../components/layout/layout'
import SEO from '../components/seo'

import IndexJumbotron from '../components/index-jumbotron'

// eslint-disable-next-line
import cardImage from '../queries/images/card-image'

// eslint-disable-next-line
import rowImage from '../queries/images/row-image'

type Data = {
  image1: GatsbyFluidImage,
  image2: GatsbyFluidImage,
  image3: GatsbyFluidImage,
  image4: GatsbyFluidImage,
  image5: GatsbyFluidImage
}

type IndexPageProps = {
  data: Data
}

const IndexPage: FC<IndexPageProps> = ({ data }: IndexPageProps) => (
  <Layout>
    <SEO
      title="Science Education Leadership Development Programs & Curriculum"
      description="BSCS Science Learning is an independent nonprofit offering science education research & leadership programs & curriculum across the US."
      canonicalUrl="https://bscs.org/"
    />
    <IndexJumbotron />
    <div className="flex w-full bg-black align-center">
      <div className="mx-auto px-8 md:px-20">
        <h2 className="text-xl sm:text-2xl text-white tracking-wide mt-10">Equity & Social Justice</h2>
        <p className="text-normal sm:text-lg text-white max-w-2xl">
          BSCS believes that science learning should be inclusive, meaningful, and advance the work of social and environmental justice. Our commitment to equity and social justice is central to our mission and at the heart of everything we do.
        </p>
        <div className="flex items-end justify-end mt-5 mb-10 w-full">
          <Link to="/our-work/equity-social-justice">
            <Button
              title='Read more'
            >
              Read more&nbsp;&nbsp;<i className="fas fa-chevron-right"></i>
            </Button>
          </Link>
        </div>
      </div>
    </div>
    <div className="py-10 px-8 md:px-20 bg-gradient-to-br from-bscs-gray-400 to-bscs-indigo-400">
      <h2 className="text-xl sm:text-2xl text-bscs-gray-100 tracking-wide max-w-screen-xl mx-auto">What We Do</h2>
      <div className="border-t-3 mt-1 border-bscs-indigo-1000 rounded max-w-screen-xl mx-auto" />

      {/* Grid */}
      <div
        className="max-w-screen-xl mx-auto grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-6 px-4 my-10"
      >

        {/* Card 1 */}
        <div className="flex flex-col flex-1 bg-bscs-gray-100 rounded-lg shadow-lg max-w-xs mx-auto">
          <div className="flex-1">
            <Img
              alt='male student holding an iPad that says "Focus Question: What are the building blocks of living things?"'
              title='male student holding an iPad that says "Focus Question: What are the building blocks of living things?"'
              className="w-full rounded-t-lg overflow-hidden"
              fluid={data.image1.childImageSharp.fluid}
            />
            <div className="px-4 mt-4">
              <h2 className="font-bold text-xl mt-0 tracking-wide">
                Science Instructional Materials
              </h2>
              <p className="text-normal">
                We develop science instructional materials for students.

                Butcher before they sold out fixie pabst lomo offal.
              </p>
            </div>
          </div>

          <Link
            to="/our-work/what-we-do#instructional-materials-development"
            className="duration-200 focus:shadow-outline font-normal font-sans outline-none overflow-hidden tracking-wider transition active:shadow-sm bg-bscs-indigo-700 border-bscs-indigo-700 focus:bg-bscs-indigo-900 focus:border-bscs-indigo-900 text-bscs-gray-100 px-4 py-3 mt-6 rounded-b-lg text-base text-center"
          >
            Read more&nbsp;&nbsp;<i className="fas fa-chevron-right"></i>
          </Link>
        </div>

        {/* Card 2 */}
        <div className="flex flex-col flex-1 bg-bscs-gray-100 rounded-lg shadow-lg max-w-xs mx-auto">
          <div className="flex-1">
            <Img
              alt='teacher with long hair and denim jacket pointing at something on a desk with three female fifth-grade students"'
              title='teacher with long hair and denim jacket pointing at something on a desk with three female fifth-grade students"'
              className="w-full rounded-t-lg overflow-hidden"
              fluid={data.image2.childImageSharp.fluid}
            />
            <div className="px-4 mt-4">
              <h2 className="font-bold text-xl mt-0 tracking-wide">
                Teacher Professional Learning
              </h2>
              <p className="text-normal">
                We provide professional learning programs for science teachers.

                Butcher before they sold out fixie pabst lomo offal.
              </p>
            </div>
          </div>

          <Link
            to="/our-work/what-we-do#teacher-professional-learning"
            className="duration-200 focus:shadow-outline font-normal font-sans outline-none overflow-hidden tracking-wider transition active:shadow-sm bg-bscs-indigo-700 border-bscs-indigo-700 focus:bg-bscs-indigo-900 focus:border-bscs-indigo-900 text-bscs-gray-100 px-4 py-3 mt-6 rounded-b-lg text-base text-center"
          >
            Read more&nbsp;&nbsp;<i className="fas fa-chevron-right"></i>
          </Link>
        </div>

        {/* Card 3 */}
        <div className="flex flex-col flex-1 bg-bscs-gray-100 rounded-lg shadow-lg max-w-xs mx-auto">
          <div className="flex-1">
            <Img
              alt=''
              title=''
              className="w-full rounded-t-lg overflow-hidden"
              fluid={data.image3.childImageSharp.fluid}
            />
            <div className="px-4 mt-4">
              <h2 className="font-bold text-xl mt-0 tracking-wide">
                Leadership Development Programs
              </h2>
              <p className="text-normal">
                We spearhead leadership development programs for schools and districts.

                Butcher before they sold out fixie pabst lomo offal.
              </p>
            </div>
          </div>

          <Link
            to="/our-work/what-we-do#leadership-development"
            className="duration-200 focus:shadow-outline font-normal font-sans outline-none overflow-hidden tracking-wider transition active:shadow-sm bg-bscs-indigo-700 border-bscs-indigo-700 focus:bg-bscs-indigo-900 focus:border-bscs-indigo-900 text-bscs-gray-100 px-4 py-3 mt-6 rounded-b-lg text-base text-center"
          >
            Read more&nbsp;&nbsp;<i className="fas fa-chevron-right"></i>
          </Link>
        </div>

        {/* Card 4 */}
        <div className="flex flex-col flex-1 bg-bscs-gray-100 rounded-lg shadow-lg max-w-xs mx-auto">
          <div className="flex-1">
            <Img
              alt="young boy with glasses and blue hoodie scrunching his face while looking at a vial in his right hand"
              title="young boy with glasses and blue hoodie scrunching his face while looking at a vial in his right hand"
              className="w-full rounded-t-lg overflow-hidden"
              fluid={data.image4.childImageSharp.fluid}
            />
            <div className="px-4 mt-4">
              <h2 className="font-bold text-xl mt-0 tracking-wide">
                Research
              </h2>
              <p className="text-normal">
                We conduct research on science teaching and learning.

                Butcher before they sold out fixie pabst lomo offal.
              </p>
            </div>
          </div>

          <Link
            to="/our-work/what-we-do#research"
            className="duration-200 focus:shadow-outline font-normal font-sans outline-none overflow-hidden tracking-wider transition active:shadow-sm bg-bscs-indigo-700 border-bscs-indigo-700 focus:bg-bscs-indigo-900 focus:border-bscs-indigo-900 text-bscs-gray-100 px-4 py-3 mt-6 rounded-b-lg text-base text-center"
          >
            Read more&nbsp;&nbsp;<i className="fas fa-chevron-right"></i>
          </Link>
        </div>
      </div>
    </div>

    <div className="py-10 px-8 md:px-20">
      {/* Grid */}
      <div
        className="max-w-screen-xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-6 my-10"
      >
        <div>
          <Img
            alt="Young girl in an astronaut suit pointing at the moon."
            className="rounded-lg max-w-xs sm:max-w-md sm:mx-auto"
            fluid={data.image5.childImageSharp.fluid}
          />
        </div>
        <div>
          <div className="max-w-sm sm:max-w-md sm:mx-auto">
            <h2 className="text-xl sm:text-2xl tracking-wide mt-0 lg:mt-5">Support BSCS Science Learning</h2>
            <p className="text-normal sm:text-lg tracking-wide max-w-lg">We are a proud 501(c)(3) nonprofit organization devoted to science education. Please consider a gift to BSCS this year and help us transform science teaching and learning. Your support makes a difference!</p>
            <div className="flex justify-start my-6 w-full">
              <Link to="/donate" title="Donate To Science R&D Programs - Teacher Instructional Materials">
                <Button
                  title="Donate now"
                  variant="violet"
                >
                  Donate now
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "index/male-student.jpg" }) {
      ...cardImage
    }
    image2: file(relativePath: { eq: "index/teacher-and-students.jpg" }) {
      ...cardImage
    }
    image3: file(relativePath: { eq: "index/young-girl.jpg" }) {
      ...cardImage
    }
    image4: file(relativePath: { eq: "index/young-boy.jpg" }) {
      ...cardImage
    }
    image5: file(relativePath: { eq: "astronaut-girl.jpg" }) {
      ...rowImage
    }
  }
`
