import React, { FC, ReactNode } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage, { IFluidObject } from 'gatsby-background-image'

import './index-jumbotron.css'


const IndexJumbotron: FC<ReactNode> = () => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "index/website_banner_2019_01.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const imageData: IFluidObject | IFluidObject[] | (string | IFluidObject)[] | undefined = data.desktop.childImageSharp.fluid

  return (
    <div className="border-t-2 border-bscs-gray-100 max-w-screen-2xl mx-auto">
      <BackgroundImage
        Tag="section"
        className="md:pb-32 md:pt-4 md:px-6 lg:pb-56"
        style={{
          // Defaults are overwrite-able by setting one or each of the following:
          backgroundSize: 'cover',
          backgroundPosition: 'center top',
          backgroundRepeat: 'no-repeat',
        }}
        fluid={imageData}
      >
        <div className="bg-bscs-indigo-600 bg-opacity-50 sm:bg-opacity-75 px-8 pt-40 pb-4 sm:py-6 md:rounded-lg max-w-full sm:max-w-sm lg:max-w-md shadow">
          <h1>
            <div className="text-white text-2xl leading-tight tracking-wider font-normal max-w-xs sm:max-w-none">Our Mission:</div>

            <div className="border-t-3 mt-1 rounded border-bscs-indigo-1000 w-16" />
            <div className="text-white text-xl sm:text-2xl mt-3 font-normal tracking-wide leading-tight max-w-xs sm:max-w-none">To transform science teaching and learning through research-driven innovation.</div>
          </h1>
        </div>
      </BackgroundImage>
    </div>
  )
}

export default IndexJumbotron
